import { URL_BACK, URL_IMAGE } from '@/config/constants'
import { cartBus } from '@/event/cartBus'
import { isNavbarVisible } from '@/event/isNavbarVisible'
import { sectionOrderBus } from '@/event/sectionOrderBus'
import { applyTheme, theme } from '@/event/theme/theme'
import { baseUrl, firstPartUrl } from '@/event/url/url'
import { configMetaTitle } from '@/storage/setting/configMetaTitle'
import stores from '@/storage/stores'
import { showSectionManagement } from '@/event/main/app/showSectionManagement'
import { fetchPost } from './fetch/fetchPost'
import { urlGetStore } from '@/api/endpoint'
import { logoHome } from '@/event/setting/logoHome'
import { injectCaptchaScript } from './captcha/injectCaptchaScript'
import { fetchPostAuthorizationCsrf } from './fetch/fetchPostAuthorizationCsrf'

export async function getStore() {
  try {
    const data = { url: baseUrl }

    const response = await fetchPostAuthorizationCsrf(urlGetStore, data)

    const nonce = response.data.nonce

    injectCaptchaScript(nonce)

    if (response.class === 'success') {
      const storeData = response.data

      logoHome.value =
        storeData.setting?.find((s: { key: string }) => s.key === 'config_logo')?.value ?? null

      if (storeData && !storeData.banner_images) {
        isNavbarVisible.value = true
        if (cartBus.value.length > 0) {
          sectionOrderBus.value = true
        }
      }

      if (storeData && storeData.setting) {
        let configMaintenance = storeData.setting.find(
          (setting: { key: string }) => setting.key === 'config_maintenance'
        )

        if (!configMaintenance) {
          configMaintenance = { key: 'config_maintenance', value: '0' }
          storeData.setting.push(configMaintenance) // On ajoute l'objet par défaut dans le store
        }

        if (configMaintenance.value === '1') {
          showSectionManagement.value = true
        }

        const openingHoursSetting = storeData.setting.find(
          (setting: { key: string }) => setting.key === 'config_opening_hours'
        )

        if (
          openingHoursSetting &&
          typeof openingHoursSetting.value === 'string' &&
          openingHoursSetting.value.length > 0
        ) {
          const openingHoursString = openingHoursSetting.value
          const openingHoursJson = JSON.parse(openingHoursString)
          if (openingHoursJson) {
            storeData.setting.find(
              (setting: { key: string }) => setting.key === 'config_opening_hours'
            ).value = openingHoursJson
          }
        }

        const deliveryZonesSetting = storeData.setting.find(
          (setting: { key: string }) => setting.key === 'config_delivery_zones'
        )
        if (
          deliveryZonesSetting &&
          typeof deliveryZonesSetting.value === 'string' &&
          deliveryZonesSetting.value.length > 0
        ) {
          const deliveryZonesJson = JSON.parse(deliveryZonesSetting.value)
          if (deliveryZonesJson) {
            storeData.setting.find(
              (setting: { key: string }) => setting.key === 'config_delivery_zones'
            ).value = deliveryZonesJson
          }
        }

        const deliveryTimeSettings = storeData.setting.find(
          (setting: { key: string }) => setting.key === 'config_delivery_time_settings'
        )

        if (
          deliveryTimeSettings &&
          typeof deliveryTimeSettings.value === 'string' &&
          deliveryTimeSettings.value.length > 0
        ) {
          const deliveryTimeSettingsJson = JSON.parse(deliveryTimeSettings.value)
          if (deliveryTimeSettingsJson) {
            storeData.setting.find(
              (setting: { key: string }) => setting.key === 'config_delivery_time_settings'
            ).value = deliveryTimeSettingsJson
          }
        }

        const configAreas = storeData.setting.find(
          (setting: { key: string }) => setting.key === 'config_areas'
        )

        if (configAreas && typeof configAreas.value === 'string' && configAreas.value.length > 0) {
          const configAreasJson = JSON.parse(configAreas.value)
          if (configAreasJson) {
            storeData.setting.find(
              (setting: { key: string }) => setting.key === 'config_areas'
            ).value = configAreasJson
          }
        }
      }

      if (storeData && storeData.config) {
        const newStoreConfig = JSON.parse(storeData.config)
        storeData.config = newStoreConfig
      }

      stores.setStoresData(storeData)

      // if (stores.state.storeData.name && stores.state.storeData.setting) {
      //   // document.title = stores.state.storeData.name

      //   const logoSetting = stores.state.storeData.setting.find(
      //     (s: { key: string }) => s.key === 'config_logo'
      //   )
      //   const iconUrl = logoSetting ? logoSetting.value : undefined

      //   // updateFavicon(iconUrl)
      // }
      // updateMetaDescription(configMetaTitle.value)

      if (stores.getStoresData().setting?.find((s: { key: string }) => s.key === 'config_theme')) {
        const themeSetting = stores
          .getStoresData()
          .setting?.find((s: { key: string }) => s.key === 'config_theme').value

        applyTheme(themeSetting)
        theme.value = themeSetting
      }
    } else {
    }
  } catch (e) {
  }
}

// function updateFavicon(iconUrl: string | undefined) {
//   let link = document.getElementById('icon-tab') as HTMLLinkElement
//   if (link && iconUrl) {
//     link.href = URL_IMAGE + iconUrl
//   } else {
//     link.href = 'icone_eronor.png'
//   }
// }
// function updateMetaDescription(content: string) {
//   const linkDescription = document.getElementById('description') as HTMLMetaElement
//   if (linkDescription && content) {
//     linkDescription.content = content
//   }
// }
