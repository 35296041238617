export const clientConfig: Record<string, {
    hostname: string
    url: string
    urlBack: string
    telephone: string
    favicon: string
    title: string
    description: string
    facebook: string
    instagram: string
    tiktok: string
}> = {
    'abikebab': {
        'hostname': 'abikebab.fr',
        'url': 'https://abikebab.fr',
        'urlBack': 'https://back.abikebab.fr',
        'telephone': '04.75.80.38.79',
        "favicon": "/image/cache/catalog/43/logo/100_100_abi_logo.png",
        "title": "ABI KEBAB NAN TACOS",
        "description": "Dispose d'une terrasse · Propose un menu enfant · Chiens autorisés à l'extérieur",
        "facebook": "",
        "instagram": "",
        "tiktok": ""

    },
    "Test O'King": {
        'hostname': 'localhost:5173',
        'url': 'https://localhost:5173',
        'urlBack': 'https://back.eronor.eu',
        'telephone': '01.02.03.04.05',
        "favicon": "/image/cache/catalog/15/logo/100_100_oking_logo.png",
        "title": "Test O'King",
        "description": "Dispose d'une terrasse · Propose un menu enfant · Chiens autorisés à l'extérieur",
        "facebook": "",
        "instagram": "",
        "tiktok": ""
    },
    "Test Eronor": {
        'hostname': 'dev.eronor.eu',
        'url': 'https://dev.eronor.eu',
        'urlBack': 'https://back.eronor.eu',
        'telephone': '01.02.03.04.05',
        "favicon": "/image/cache/catalog/13/logo/100_100_gout_menthe.png",
        "title": "Test Eronor",
        "description": "Dispose d'une terrasse · Propose un menu enfant · Chiens autorisés à l'extérieur",
        "facebook": "",
        "instagram": "",
        "tiktok": ""
    },
    "Irfan Kebab": {
        'hostname': 'menuboard.fr',
        'url': 'https://menuboard.fr',
        'urlBack': 'https://back.eronor.eu',
        'telephone': '01.02.03.04.05',
        "favicon": "/image/cache/catalog/12/logo/100_100_gout_citron.png",
        "title": "Irfan Kebab",
        "description": "Dispose d'une terrasse · Propose un menu enfant · Chiens autorisés à l'extérieur",
        "facebook": "",
        "instagram": "",
        "tiktok": ""
    }
}